import React from 'react'
import { Footer } from '../components/Footer'
import { NavBarService } from '../components/NavbarService'
import BannerService from '../components/BannerService'
import headerImg from "../assets/img/banner5.png";
import { Services } from '../components/Services';

const IT = () => {
  return (
    <>
    <NavBarService/>
    <BannerService heading = "IT Infrastructure Design" imageSrc = {headerImg} paragraph = "We deliver dynamic IT services and solutions for business requirements. We are equipped with highly qualified and experienced professionals with all the required skills sets and ability to handle any project.We offer a wide range of IT solutions and services including web design & development, software development, surveillance services (CCTV), Paper Digitization, IT infrastructure design, and building management system (BMS)."/>
    <Services/>
    <Footer/>
    </>
  )
}

export default IT