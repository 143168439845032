import React from 'react'
import { Footer } from '../components/Footer'
import { NavBarService } from '../components/NavbarService'
import BannerService from '../components/BannerService'
import headerImg from "../assets/img/banner2.png";
import { Services } from '../components/Services';
const Mobile = () => {
  return (
    <>
    <NavBarService/>
    <BannerService heading = "Mobile App Development" imageSrc = {headerImg} paragraph = "Our team of professional Mobile apps and software developers thrive to develop the best mobile apps with special emphasis on usability for all leading Mobile operating platforms. We start from the blank page and develop the complete software that will fulfill the requirement you are looking for.Our mobile platform expertise includes: iPhone, Android Application Development"/>
    <Services/>
    <Footer/>
    </>
  )
}

export default Mobile