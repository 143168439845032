// import { useEffect, useState } from "react";
// import { Container, Row, Col } from "react-bootstrap";
// import contactImg from "../assets/img/contact-img.svg";
// import location from "../assets/img/location.png";
// import 'animate.css';
// import TrackVisibility from 'react-on-screen';
// import { Footer } from "../components/Footer";
// import { NavBarService } from "../components/NavbarService";
// import Aos from 'aos';
// import 'aos/dist/aos.css';

// export const ContactPage = () => {
//   useEffect(() => {
//     Aos.init({ duration:2000 });
//  }, [])
//   const formInitialDetails = {
//     firstName: '',
//     lastName: '',
//     email: '',
//     phone: '',
//     message: ''
//   }
//   const [formDetails, setFormDetails] = useState(formInitialDetails);
//   const [buttonText, setButtonText] = useState('Send');
//   const [status, setStatus] = useState({});

//   const onFormUpdate = (category, value) => {
//     setFormDetails({
//       ...formDetails,
//       [category]: value
//     })
//   }

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setButtonText("Sending...");
//     let response = await fetch("http://localhost:5000/contact", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json;charset=utf-8",
//       },
//       body: JSON.stringify(formDetails),
//     });
//     setButtonText("Send");
//     let result = await response.json();
//     setFormDetails(formInitialDetails);
//     if (result.code == 200) {
//       setStatus({ succes: true, message: 'Message sent successfully' });
//     } else {
//       setStatus({ succes: false, message: 'Something went wrong, please try again later.' });
//     }
//   };

//   return (
//     <>
//       <NavBarService />
//       <section className="contactP" id="contact">

//         <Container>

//           <Row>
//           <h2 className="contact-heading">Contact Us</h2>
//             {/* First Column */}
//             <Col md={6} className="contact-info">

//               <h1>Pakistan Office</h1>
//               <div style={{marginBottom:'20px'}}>
//               <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d28950.723915352424!2d67.076154!3d24.903422!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb3393643d84c61%3A0x22114fd6e9afd77e!2sDynamics%20Technology%20Systems%20(DTS)!5e0!3m2!1sen!2sus!4v1692335228001!5m2!1sen!2sus" width="600" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
//               </div>
//               <p>109, First Floor, Silver Trade Tower,<br />
//                 Gulshan-e-Iqbal, Karachi, Pakistan</p>
//               <h4><span style={{ fontWeight: "bold" }}>Phone:</span> +92-333-31234-28</h4>
//               <h4><span style={{ fontWeight: "bold" }}>Email:</span> info@dtspk.com</h4>
//               <h3>Development & Support Office: </h3>
//               <p>A – 30, Second Floor, Block 13 – A,<br />
//                 Main University Road, Gulshan-e-Iqbal,<br />
//                 Karachi, Pakistan.</p>
//               <h4><span style={{ fontWeight: "bold" }}>Phone:</span> +92-21-34155599</h4>
//               <h4><span style={{ fontWeight: "bold" }}>Email:</span> info@dtspk.com</h4>
//               <h3>Business Hours:</h3>
//               <p>Monday – Friday : 8:00am to 6:00pm</p>
//             </Col>

//             {/* Second Column */}
//             <Col md={6} className="contact-info">

//               <h1>USA Office</h1>
//               <div style={{marginBottom:'20px'}}>
//               <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5954.025765555161!2d-88.216167!3d41.741815!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880ef77b58662259%3A0xd9d8ba8330e627b5!2s4065%20Heinz%20Dr%2C%20Aurora%2C%20IL%2060504!5e0!3m2!1sen!2sus!4v1692335320326!5m2!1sen!2sus" width="600" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
//               </div>
//               <p>4065 Heinz Drive<br />
//                 Aurora, Illinois, USA</p>
//               <h4><span style={{ fontWeight: "bold" }}>Phone:</span> +630-499-1105</h4>
//               <h4><span style={{ fontWeight: "bold" }}>Email:</span> mohammad.moizuddin@dtspk.com</h4>
//               <Col size={12} md={6}>
//               <TrackVisibility>
//                 {({ isVisible }) =>
//                   <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={location} alt="Contact Us" style={{height:"350px", width:"400px"}}/>
//                 }
//               </TrackVisibility>
//             </Col>
//             </Col>
//             <Col size={12} md={6}>
//               <TrackVisibility>
//                 {({ isVisible }) =>
//                   <img className={isVisible ? "animate__animated animate__zoomIn c-img1" : ""} src={contactImg} alt="Contact Us" />
//                 }
//               </TrackVisibility>
//             </Col>
//             <Col size={12} md={6}>
//               <TrackVisibility>
//                 {({ isVisible }) =>
//                   <div className={isVisible ? "animate__animated animate__fadeIn" : ""} style={{ marginTop: "50px" }}>
//                     <h2>Get In Touch</h2>
//                     <form onSubmit={handleSubmit}>
//                       <Row>
//                         <Col size={12} sm={6} className="px-1" style={{ marginBottom: '15px' }}>
//                           <input type="text" value={formDetails.firstName} placeholder="First Name" onChange={(e) => onFormUpdate('firstName', e.target.value)} />
//                         </Col>
//                         <Col size={12} sm={6} className="px-1" style={{ marginBottom: '15px' }}>
//                           <input type="text" value={formDetails.lastName} placeholder="Last Name" onChange={(e) => onFormUpdate('lastName', e.target.value)} />
//                         </Col>
//                         <Col size={12} sm={6} className="px-1" style={{ marginBottom: '15px' }}>
//                           <input type="email" value={formDetails.email} placeholder="Email Address" onChange={(e) => onFormUpdate('email', e.target.value)} />
//                         </Col>
//                         <Col size={12} sm={6} className="px-1" style={{ marginBottom: '15px' }}>
//                           <input type="tel" value={formDetails.phone} placeholder="Phone No." onChange={(e) => onFormUpdate('phone', e.target.value)} />
//                         </Col>
//                         <Col size={12} className="px-1">
//                           <textarea rows="6" value={formDetails.message} placeholder="Message" onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
//                           <button type="button"><span>{buttonText}</span></button>
//                         </Col>
//                         {
//                           status.message &&
//                           <Col>
//                             <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
//                           </Col>
//                         }
//                       </Row>
//                     </form>
//                   </div>}
//               </TrackVisibility>
//             </Col>
//           </Row>
//         </Container>
//       </section>
//       <Footer />
//     </>
//   )
// }










import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import location from "../assets/img/location.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { Footer } from "../components/Footer";
import { NavBarService } from "../components/NavbarService";
import Aos from 'aos';
import 'aos/dist/aos.css';

export const ContactPage = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  };

  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Send');
  const [status, setStatus] = useState({ message: '', success: null });
  const [submitted, setSubmitted] = useState(false);

  const validatePhone = (phone) => {
    const phoneRegex = /^\d{11}$/;
    return phoneRegex.test(phone);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    setButtonText("Sending...");

    if (!formDetails.firstName || !formDetails.lastName || !formDetails.email || !formDetails.phone || !formDetails.message) {
      setStatus({ success: false, message: 'Please fill in all fields.' });
      setButtonText("Send");
      return;
    } else if (!validateEmail(formDetails.email)) {
      setStatus({ success: false, message: 'Please enter a valid email address.' });
      setButtonText("Send");
      return;
    } else if (!validatePhone(formDetails.phone)) {
      setStatus({ success: false, message: 'Please enter a valid phone number.' });
      setButtonText("Send");
      return;
    }

    try {
      const formData = new FormData(e.target);
      const data = Object.fromEntries(formData);
      const response = await fetch('submit_contact.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      });

      const result = await response.text();
      alert(result);

      if (response.ok) {
        setStatus({ success: true, message: 'Message sent successfully' });
        setFormDetails(formInitialDetails); // Reset form fields
        setSubmitted(false);
      } else {
        setStatus({ success: false, message: 'Something went wrong, please try again later.' });
      }
    } catch (error) {
      setStatus({ success: false, message: 'Error occurred while sending the message.' });
    } finally {
      setButtonText("Send");
    }
  };
  return (
    <>
      <NavBarService />
      <section className="contactP" id="contact">
        <Container>
          <Row>
            <h2 className="contact-heading">Contact Us</h2>
            {/* First Column */}
            <Col md={6} className="contact-info">
              <h1>Pakistan Office</h1>
              <div style={{ marginBottom: '20px' }}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d28950.723915352424!2d67.076154!3d24.903422!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb3393643d84c61%3A0x22114fd6e9afd77e!2sDynamics%20Technology%20Systems%20(DTS)!5e0!3m2!1sen!2sus!4v1692335228001!5m2!1sen!2sus" width="600" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
              <p>109, First Floor, Silver Trade Tower,<br />
                Gulshan-e-Iqbal, Karachi, Pakistan</p>
              <h4><span style={{ fontWeight: "bold" }}>Phone:</span> +92-333-31234-28</h4>
              <h4><span style={{ fontWeight: "bold" }}>Email:</span> info@dtspk.com</h4>
              <h3 style={{color:'#a6aaff'}}>Development & Support Office: </h3>
              <p>A – 30, Second Floor, Block 13 – A,<br />
                Main University Road, Gulshan-e-Iqbal,<br />
                Karachi, Pakistan.</p>
              <h4><span style={{ fontWeight: "bold" }}>Phone:</span> +92-21-34155599</h4>
              <h4><span style={{ fontWeight: "bold" }}>Email:</span> info@dtspk.com</h4>
              <h3 style={{color:'#a6aaff'}}>Business Hours:</h3>
              <p>Monday – Friday : 8:00am to 6:00pm</p>
            </Col>

            {/* Second Column */}
            <Col md={6} className="contact-info">
              <h1>USA Office</h1>
              <div style={{ marginBottom: '20px' }}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5954.025765555161!2d-88.216167!3d41.741815!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880ef77b58662259%3A0xd9d8ba8330e627b5!2s4065%20Heinz%20Dr%2C%20Aurora%2C%20IL%2060504!5e0!3m2!1sen!2sus!4v1692335320326!5m2!1sen!2sus" width="600" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
              <p>4065 Heinz Drive<br />
                Aurora, Illinois, USA</p>
              <h4><span style={{ fontWeight: "bold" }}>Phone:</span> +630-499-1105</h4>
              <h4><span style={{ fontWeight: "bold" }}>Email:</span> mohammad.moizuddin@dtspk.com</h4>
              <Col size={12} md={6}>
                <TrackVisibility>
                  {({ isVisible }) =>
                    <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={location} alt="Contact Us" style={{ height: "300px", width: "350px" }} />
                  }
                </TrackVisibility>
              </Col>
            </Col>
            <Col size={12} md={6}>
              <TrackVisibility>
                {({ isVisible }) =>
                  <img className={isVisible ? "animate__animated animate__zoomIn c-img1" : ""} src={contactImg} alt="Contact Us" />
                }
              </TrackVisibility>
            </Col>
            <Col size={12} md={6}>
              <TrackVisibility>
                {({ isVisible }) =>
                  <div className={isVisible ? "animate__animated animate__fadeIn" : ""} style={{ marginTop: "50px" }}>
                    <h2>Get In Touch</h2>
                    <form onSubmit={handleSubmit} id="contactForm">
                      <Row>
                        <Col size={12} sm={6} className="px-1" style={{ marginBottom: '15px' }}>
                          <input type="text" id="first_name" name="first_name" value={formDetails.firstName} placeholder="First Name" onChange={(e) => onFormUpdate('firstName', e.target.value)} />
                          {submitted && status.message && !formDetails.firstName && <p className="text-warning">Please enter your first name.</p>}
                        </Col>
                        <Col size={12} sm={6} className="px-1" style={{ marginBottom: '15px' }}>
                          <input type="text" id="last_name" name="last_name" value={formDetails.lastName} placeholder="Last Name" onChange={(e) => onFormUpdate('lastName', e.target.value)} />
                          {submitted && status.message && !formDetails.lastName && <p className="text-warning">Please enter your last name.</p>}
                        </Col>
                        <Col size={12} sm={6} className="px-1" style={{ marginBottom: '15px' }}>
                          <input type="email" id="email_address" name="email_address" value={formDetails.email} placeholder="Email Address" onChange={(e) => onFormUpdate('email', e.target.value)} />
                          {submitted && status.message && !formDetails.email && <p className="text-warning">Please enter your email address.</p>}
                          {submitted && status.message && formDetails.email && !validateEmail(formDetails.email) && <p className="text-warning">Please enter a valid email address.</p>}
                        </Col>
                        <Col size={12} sm={6} className="px-1" style={{ marginBottom: '15px' }}>
                          <input type="tel" id="phone_no" name="phone_no" value={formDetails.phone} placeholder="Phone No." onChange={(e) => onFormUpdate('phone', e.target.value)} />
                          {submitted && status.message && !formDetails.phone && <p className="text-warning">Please enter your phone number.</p>}
                          {submitted && status.message && formDetails.phone && !validatePhone(formDetails.phone) && <p className="text-warning">Please enter a valid phone number.</p>}
                        </Col>
                        {/* <Col size={12} className="px-1">
                        <textarea rows="6" value={formDetails.message} placeholder="Message" onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                          {status.message && !formDetails.message && <p className="danger">Please enter your message.</p>}     
                          <button type="submit"><span>{buttonText}</span></button>
                        </Col> */}
                        <Col size={12} className="px-1">
                          <textarea rows="6" id="message" name="message" value={formDetails.message} placeholder="Message" onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                          {submitted && status.message && !formDetails.message && <p className="text-warning">Please enter your message.</p>}
                          <button type="submit"><span>{buttonText}</span></button>
                          {status.success && <h5 className="mt-4 success">Message sent successfully!</h5>}
                          {submitted && status.message && !status.success && <h5 className="mt-4 text-warning">{status.message}</h5>}
                        </Col>
                      </Row>
                      {/* {status.message && (
                          <Col>
                            <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                          </Col>
                        )} */}
                    </form>
                  </div>
                }
              </TrackVisibility>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
};
