import React from 'react'
import { Footer } from '../components/Footer'
import { NavBarService } from '../components/NavbarService'
import BannerService from '../components/BannerService'
import headerImg from "../assets/img/banner3.png";
import { Services } from '../components/Services';

const Mobile = () => {
  return (
    <>
    <NavBarService/>
    <BannerService heading = "Software Development" imageSrc = {headerImg} paragraph = "We believe in developing customized solutions specific to each organizations needs and it begins with requirement analysis. Based on the complexity and project size. At DTS, we design software step by step along its processing."/>
    <Services/>
    <Footer/>
    </>
  )
}

export default Mobile