import React from 'react'
import { Footer } from '../components/Footer'
import { NavBarService } from '../components/NavbarService'
import BannerService from '../components/BannerService'
import headerImg from "../assets/img/banner1.png";
// import { FooterServices } from '../components/FooterServices';
import { Services } from '../components/Services';

const Web = () => {
  return (
    <>
    <NavBarService/>
    <BannerService heading = "Web Designing & Development" imageSrc = {headerImg} paragraph = " Websites are the visible face of your business. The way your customers view and think about your business. It is a platform from which you can project your brand and your vision on an international scale. At DTS, we offer a comprehensive and professional web design service to customers of all sizes, including small businesses, corporate organizations and individuals. Whether you need a one page brochure site or a complex database driven web application, we can cater for you."/>
    <Services/>
    <Footer/>
    </>
  )
}

export default Web