import { useEffect } from "react";
import about from "../assets/img/about.png";
import mission from "../assets/img/mission.png";
import mission1 from "../assets/img/mission1.jpeg";
import vision from "../assets/img/vision.png";
import vision1 from "../assets/img/vision1.jpeg";
import Aos from 'aos';
import 'aos/dist/aos.css';

export const About = () => {
    useEffect(() => {
        Aos.init({ duration:2000 });
     }, [])
    return (
        <section className="about" id="about">
            <h2>About Us</h2>
            <div className="about-wrapper">
                <p className="about-para" >DTS deliver dynamic IT services and solutions for business requirements. We are equipped with highly qualified and experienced professionals with all the required skills sets and ability to handle any project.
                    DTS offer a wide range of IT solutions and services including web design & development, software development, IT infrastructure design.</p>
                <img src={about} className="about-img" alt="about" />
            </div>
                            {/* New Content */}
                            <div className="about-row">
                    <div className="about-column">
                    
                        <h3><img src={mission} alt="mission" style={{height:"70px", width:"70px"}}/>Our Mission</h3>
                        <div className="about-content">
                        <img src={mission1} className="about-icon" alt="mission" />
                        <p>To create, deliver and support adoptable business software and services that help organizations to manage their dynamic business needs effectively. To provide cost effective, consistent, reliable and flexible solutions and services. To provide high end quality services to our customers through our services excellence & knowledge.</p>
                        </div>
                    </div>
                    <div className="about-column">
                    
                        <h3><img src={vision} alt="mission" style={{height:"70px", width:"70px"}}/>Our Vision</h3>
                        <div  className="about-content">
                        <img src={vision1} className="about-icon" alt="mission" />
                        <p>To be recognized and valued as one of the leading solution provider of IT services. To deliver IT enabled services and add all our efforts to help our clients to achieve their strategic organizational objectives. To distribute customized services meeting standards through high quality contemporary technologies.</p>
                        </div>
                    </div>
                </div>
        </section>
    );
};
