import React from 'react'
import { Footer } from '../components/Footer'
import { NavBarService } from '../components/NavbarService'
import BannerService from '../components/BannerService'
import headerImg from "../assets/img/banner6.png";
import { Services } from '../components/Services';

const TimeAttendence = () => {
  return (
    <>
    <NavBarService/>
    <BannerService heading = "Time & Attendance Solutions" imageSrc = {headerImg} paragraph = "Organizations of all sizes use time and attendance systems to record when employees start and stop work, and the department where the work is performed. However, it’s also common to track breaks.DTS providing comprehensive solutions for Time and Attendance system. We are dealing in most reputed manufacturers of Biometric Time and Attendance equipment’s in Pakistan."/>
    <Services/>
    <Footer/>
    </>
  )
}

export default TimeAttendence