import React from "react";
import { NavBar } from "../components/NavBar";
import { Banner } from "../components/Banner";
import { Skills } from "../components/Skills";
import { Projects } from "../components/Projects";
import { Contact } from "../components/Contact";
import { Footer } from "../components/Footer";
import { About } from '../components/About';
import { Services } from '../components/Services';
import { Clients } from '../components/Clients';

const LandingPage = () => {
  return (
    <div className="App">
      <NavBar />
      <Banner />
      <Skills />
      <About/>
      <Services/>
      <Projects />
      <Clients/>
      <Contact />
      <Footer />
    </div>
  );
}

export default LandingPage;
