import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import proj1 from "../assets/img/project1.png";
import proj2 from "../assets/img/project2.png";
import proj3 from "../assets/img/project3.png";
import proj4 from "../assets/img/project4.png";
import proj5 from "../assets/img/project5.png";
import proj6 from "../assets/img/project6.png";
import proj7 from "../assets/img/project7.png";
import proj8 from "../assets/img/project8.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";

export const Projects = () => {
  useEffect(() => {
    Aos.init({ duration:2000 });
 }, [])
  const projects = [
    {
      title: "AI Chatbot on Xpertly",
      description: "Artificial Intelligence",
      imgUrl: proj1,
    },
    {
      title: "KWTRO Plants Websites",
      description: "Website Design & Development",
      imgUrl: proj2,
    },
    {
      title: "Intelligent Reports & Dashboards with Strategic Impact for MAA International Australia",
      description: "Power BI",
      imgUrl: proj3,
    },
    {
      title: "Empowering 'Mazaya Real Estate' by Our Apps Revolutionizing Business Operations",
      description: "Microsoft SharePoint",
      imgUrl: proj4,
    },
    // {
    //   title: "Machinoweb",
    //   description: "Website Design & Development",
    //   imgUrl: proj5,
    // },
    {
      title: "Meals of Mercy",
      description: "Mobile Application",
      imgUrl: proj6,
    },
    {
      title: "MAA International, Australia",
      description: "Kentico",
      imgUrl: proj7,
    },
    {
      title: "Managing Business of Non-Profit Organization, MAA International, Australia",
      description: "CRM",
      imgUrl: proj8,
    },
  ];

 // Define the number of projects per tab
 const projectsPerTab = 9;

 // Calculate the number of tabs needed based on the number of projects
 const numTabs = Math.ceil(projects.length / projectsPerTab);

 // Generate an array of tabs
 const tabArray = Array.from({ length: numTabs }, (_, index) => index + 1);

 return (
   <section className="project" id="projects">
     <Container>
       <Row>
         <Col size={12}>
           <TrackVisibility>
             {({ isVisible }) => (
               <div className={isVisible ? 'animate__animated animate__fadeIn' : ''}>
                 <h2>Our Projects</h2>
                 <Tab.Container  id="projects-tabs" defaultActiveKey="1">
                   <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                     {/* {tabArray.map((tabNumber) => (
                       <Nav.Item key={tabNumber}>
                         <Nav.Link eventKey={tabNumber.toString()}></Nav.Link>
                       </Nav.Item>
                     ))} */}
                   </Nav>
                   <Tab.Content id="slideInUp" className={isVisible ? 'animate__animated animate__slideInUp' : ''}>
                     {tabArray.map((tabNumber) => (
                       <Tab.Pane key={tabNumber} eventKey={tabNumber.toString()}>
                         <Row>
                           {projects
                             .slice((tabNumber - 1) * projectsPerTab, tabNumber * projectsPerTab)
                             .map((project, index) => (
                               <ProjectCard key={index} {...project} />
                             ))}
                         </Row>
                       </Tab.Pane>
                     ))}
                   </Tab.Content>
                 </Tab.Container>
               </div>
             )}
           </TrackVisibility>
         </Col>
       </Row>
     </Container>
     <img className="background-image-right" src={colorSharp2} alt="Background"></img>
   </section>
 );
}
